<template>
  <div complete-cz>
    <div class="complete-view" v-if="isCompleteView">
      <ProgressTitle :is-final="true" />
      <FontIcon name="check-over-circle" />
      <h2>{{ $t('thankYou') }}</h2>
      <div>
        <p>{{ $t('_.descriptionCz1') }}</p>
        <div>{{ $t('_.descriptionCz2') }} <a @click="changeView({ goHomeAddressView: true })">{{$t('_.here')}}</a> {{$t('_.optional')}}</div>
      </div>
      <ProgressButton button-name="complete" @click="complete" />
    </div>
    <div v-else>
      <HomeAddress v-if="isHomeAddressView" @change-view="changeView" />
      <MailingAddress v-if="isMailingAddressView" @change-view="changeView" />
      <DataBox v-if="isDataBoxView" @change-view="changeView" :mailingAddress="mailingAddressInfo" />
      <CompleteContactAddress v-if="isUpdatedView" @change-view="changeView "/>
    </div>
  </div>
</template>

<script>
import { webSiteInfo } from '@/utils/webSiteUtil';
import { state } from '@shared/utils/storeUtils.mjs';
import { completeRedirectUrl } from '@/utils/baseUtil';
import { autoLogin, close, isClient } from '@/utils/clientUtil';
import { getCookie } from '@shared/utils/cookieUtils.mjs';
import ProgressButton from '@/views/components/common/ProgressButton.vue';
import ProgressTitle from '@/views/components/pages/on-boarding/ProgressTitle.vue';
import HomeAddress from '@/views/components/pages/on-boarding/template/extra/contact-address/HomeAddress.vue';
import MailingAddress from '@/views/components/pages/on-boarding/template/extra/contact-address/MailingAddress.vue';
import DataBox from '@/views/components/pages/on-boarding/template/extra/contact-address/DataBox.vue';
import CompleteContactAddress from '@/views/components/pages/on-boarding/template/complete/CompleteContactAddress.vue';
import FontIcon from '@shared/components/common/FontIcon.vue';
import { restrictType } from '@/constants/base/onboarding/kyc';

export default {
  name: 'CompleteCz',
  components: { DataBox, CompleteContactAddress, MailingAddress, FontIcon, ProgressButton, ProgressTitle, HomeAddress },
  lexicon: 'onBoarding.complete',
  data() {
    return {
      // 테스트 계정: aisg_s01_testuser23352@tnsuslab.com
      webSiteUrl: null,
      webSiteName: null,
      isCompleteView: true,
      isHomeAddressView: false,
      isMailingAddressView: false,
      isDataBoxView: false,
      isUpdatedView: false,
      homeAddressInfo: null,
      mailingAddressInfo: null,
    };
  },
  computed: {
    site: state('env', 'site'),
    widgetUrl: state('env', 'widgetUrl'),
    token: state('user', 'token'),
    selectedLang: state('query', 'selectedLang'),
    isMobile: state('browser', 'mobile'),
    language() {
      return this.$services.cookie.getLanguage();
    },
  },
  methods: {
    initialize() {
      const { name, url } = webSiteInfo(this.site);
      this.webSiteName = name;
      this.webSiteUrl = url;
    },
    goCashier() {
      const deviceType = this.isMobile ? 'mobileType' : 'pcType';
      window.location.href = `${this.widgetUrl}/Cashier?ViewType=${deviceType}&token=${this.token}&language=${this.selectedLang}`;
    },
    async complete() {
      if (!completeRedirectUrl()) {
        if (isClient()) {
          const kycStatusInfo = await this.$services.kyc.getPlayerKycStatus();
          const existRestrictLogin = kycStatusInfo?.KycRequisition?.Restrics?.includes(restrictType.Login);
          if (existRestrictLogin) close(this);
          else autoLogin(this, { site: this.site, token: await this.$services.sign.getGcLoginToken() });
        } else close(this);
      }
    },
    changeView(v) {
      this.initView();
      this.updatePayload(v);
      if (v.goHomeAddressView) this.isHomeAddressView = true;
      if (v.goMailingView) this.isMailingAddressView = true;
      if (v.goDataBoxView) this.isDataBoxView = true;
      if (v.goUpdatedView) this.isUpdatedView = true;
    },
    initView() {
      this.isCompleteView = false;
      this.isHomeAddressView = false;
      this.isMailingAddressView = false;
      this.isDataBoxView = false;
      this.isUpdatedView = false;
    },
    updatePayload(v) {
      this.mailingAddressInfo = v.mailingAddressInfo;
    },
  },
  mounted() {
    this.initialize();
  }
};
</script>

<style lang="less">
@import "~@/less/proj.less";
[complete-cz] { .tc(); .pt(24);
  .complete-view {
    [font-icon] { .p(16); .fs(50); .c(#1db77d); }
    h2 { .fs(32); .mt(12); .regular(); }
    > div { .fs(18, 20); .mt(40); .c(@c-text-desc); .regular(); .mb(55); .tc();
      p { .mb(20); .c(#ebebf0); }
      a { .mt(30); .c(@c-link); }
    }
  }
  [checkbox-item] { .mb(20); .c(#ccc);
    > em { .fs(16); }
  }
  .field-holder { .flex(); .gap(8);
    > :nth-of-type(1) { .flex-grow(2); }
  }
  [validation-component] {
    .holder { .tl(); }
    .disabled span input { .c(#7c7c7c); .regular(); }
    .disabled div a { pointer-events: none;
      label { .c(#7c7c7c); .regular(); }
    }
  }
  [czech-country-selector] { .m(8, 0); }
  [progress-button] { .mt(50); .fs(20); }
}
</style>
