<template>
  <div onboarding-complete>
    <component :is="template"/>
  </div>
</template>

<script>
import { state } from '@shared/utils/storeUtils.mjs';
import { siteIds } from '@/constants/base/siteMap';
import CompleteCommon from '@/views/components/pages/on-boarding/template/complete/CompleteCommon.vue';
import CompleteWsop from '@/views/components/pages/on-boarding/template/complete/CompleteWsop.vue';
import CompleteCz from '@/views/components/pages/on-boarding/template/complete/CompleteCz.vue';
import { trackGtmEvent, trackSegment } from '@/plugins/tracking';

export default {
  name: 'Complete',
  computed: {
    site: state('env', 'site'),
    template() {
      if (this.site === siteIds.WSOPON) return CompleteWsop;
      if (this.site === siteIds.GGPCZ) return CompleteCz;
      return CompleteCommon;
    },
  },
  mounted() {
    trackGtmEvent({ 'event': 'onboarding_end', 'SiteId': this.site });
    trackSegment('onboarding_end');
  },
  beforeDestroy() {
    this.$services.cookie.removeToken();
    this.$services.cookie.removeIsFromMobileApp();
    this.$services.cookie.removeIsFromClientApp();
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[onboarding-complete] {}
</style>