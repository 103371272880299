<template>
  <div complete-contact-address>
    <ProgressTitle :default-label="'onBoarding.title.contactAddressUpdate'" :is-final="true" />
    <FontIcon name="check-over-circle" />
    <h2>{{ $t('thankYou') }}</h2>
    <div>
      <p>{{ $t('_.completeUpdated') }}</p>
    </div>
    <ProgressButton button-name="complete" @click="complete" />
  </div>
</template>

<script>
import { webSiteInfo } from '@/utils/webSiteUtil';
import { state } from '@shared/utils/storeUtils.mjs';
import { completeRedirectUrl } from '@/utils/baseUtil';
import { autoLogin, close, isClient } from '@/utils/clientUtil';
import { getCookie } from '@shared/utils/cookieUtils.mjs';
import ProgressButton from '@/views/components/common/ProgressButton.vue';
import ProgressTitle from '@/views/components/pages/on-boarding/ProgressTitle.vue';
import FontIcon from '@shared/components/common/FontIcon.vue';
import { restrictType } from '@/constants/base/onboarding/kyc';

export default {
  name: 'CompleteContactAddress',
  lexicon: 'onBoarding.contactAddress',
  components: { FontIcon, ProgressButton, ProgressTitle },
  data() {
    return {
      webSiteUrl: null,
      webSiteName: null,
    };
  },
  computed: {
    site: state('env', 'site'),
    token: state('user', 'token'),
    widgetUrl: state('env', 'widgetUrl'),
    selectedLang: state('query', 'selectedLang'),
    isMobile: state('browser', 'mobile'),
    language() {
      return this.$services.cookie.getLanguage();
    },
  },
  methods: {
    initialize() {
      const { name, url } = webSiteInfo(this.site);
      this.webSiteName = name;
      this.webSiteUrl = url;
    },
    goCashier() {
      const deviceType = this.isMobile ? 'mobileType' : 'pcType';
      window.location.href = `${this.widgetUrl}/Cashier?ViewType=${deviceType}&token=${this.token}&language=${this.selectedLang}`;
    },
    async complete() {
      if (!completeRedirectUrl()) {
        if (isClient()) {
          const kycStatusInfo = await this.$services.kyc.getPlayerKycStatus();
          const existRestrictLogin = kycStatusInfo?.KycRequisition?.Restrics?.includes(restrictType.Login);
          if (existRestrictLogin) close(this);
          else autoLogin(this, { site: this.site, token: await this.$services.sign.getGcLoginToken() });
        } else close(this);
      }
    },
  },
  mounted() {
    this.initialize();
  }
};
</script>

<style lang="less">
@import "~@/less/proj.less";
[complete-contact-address] { .tc(); .pt(24);
  [font-icon] { .p(16); .fs(50); .c(#1db77d); }
  h2 { .fs(32); .mt(12); .regular(); }
  > div { .fs(18, 20); .mt(40); .c(@c-text-desc); .regular(); .mb(55); .tc();
    p { .mb(20); .c(#ccc); }
  }
}
</style>
